<template>
  <section class="Dashboard">
    <Breadcrumbs />
    <dashboard-top>
      <screen-title title="Add new player"></screen-title>
    </dashboard-top>
    <div class="container ml-0">
      <section-title title="Create player"></section-title>
      <Form :events="events"></Form>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import Form from "@/components/Forms/NewPlayer";

export default {
  name: "CreatePlayer",
  components: { Form },
  mounted() {
    if (this.$store.state.events.all == null) {
      this.$store.dispatch('events/GET_EVENTS')
    }
  },
  computed: {
    ...mapState({
      events: state => state.events.all
    })
  }
};
</script>